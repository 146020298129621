<template>
  <footer>
    <div class="container">
      <div class="left link-container">
        <a target="_blank" href="https://esprezzo.io/">esprezzo.io</a>
        <a target="_blank" href="https://docs.esprezzo.io/">Documentation</a>
        <a target="_blank" href="https://help.esprezzo.io">Help Center</a>
      </div>
      <div class="flex-grow" />
      <div class="right link-container">
        <a target="_blank" href="https://esprezzo.io/terms">Terms of Service</a>
        <a target="_blank" href="https://esprezzo.io/privacy">Privacy</a>
        <span>© {{ year }} Esprezzo</span>
      </div>
    </div>
  </footer>
</template>

<script>

  export default {
    data() {
      return {
        year: 2021,
      }
    },
    created() {
      this.year = new Date().getYear() + 1900
    },
  }

</script>

<style lang="stylus" scoped>

  footer
    @apply p-4
    @apply text-white
    @apply bg-purple-1000

    @apply flex
    @apply items-center
    @apply justify-center

    min-height: theme('height.16')

    a
      @apply text-white
      @apply font-normal

  .container
    @apply mx-auto

    @apply flex
    @apply flex-col

    +breakpoint(sm)
      @apply flex-row

  .link-container
    @apply flex
    @apply flex-col
    @apply space-y-2

    &.right
      @apply mt-2

    +breakpoint(sm)
      @apply flex-row
      @apply space-y-0
      @apply space-x-4

      &.right
        @apply mt-0

</style>
